
import { gtm } from '@/util/GTMService';
import {httpService} from "@/util/HttpAdapter";
import { Component, Vue } from 'vue-property-decorator'
import {v4 as uuidv4} from "uuid";

@Component({})
export default class KionOzonButon extends Vue {

	subscriptionExist = !!window.sessionStorage.getItem("subscriptionExist")

	mounted() {
	this.subscriptionExist = !!window.sessionStorage.getItem("subscriptionExist")
	}

	webSsoAuth() {
	window.sessionStorage.setItem("request_id", uuidv4())
	gtm.pushEvent({
		event: 'authorization_start',
		event_name: 'authorization_start',
		referer: httpService.previousPage,
		auth_method: "sms",
		auth_type: 'websso',
		request_id: window.sessionStorage.getItem("request_id")
	})
	const hostUrl = window.location.host
	window.location.assign(
		encodeURI(
			`https://prod.tvh.mts.ru/tvh-public-api-gateway/public/rest/general/websso/redirect?state=${ window.sessionStorage.getItem("request_id") + ':' }PGNVY&clientType=WEB&domain=${hostUrl}`
		)
	);
	}
}
